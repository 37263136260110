/* colors.css */
:root {
    --primary: #FFA500;
    --secondary: #FFF6E6;
    --background: #FFFFFF;
    --text: #000000;
    /* Add more colors as needed */
}


body {
    font-family: 'Avenir';
}

.navbar {
    background: none !important;
}

.noscrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noscrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

#countrylist {
    color: white;
    display: flex;
    max-width: 29.5rem;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 1.875rem !important;
    border: 1px solid rgba(239, 239, 239, 0.2) !important;
    border-image: initial;
    margin-top: 1rem;
    background: rgba(255, 255, 255, 0.7);
    justify-content: space-between;
    align-items: center;
    box-shadow: none !important;
    overflow: hidden;
}

#countrylist::-webkit-scrollbar {
    display: none;
}

#countrylist a {
    background: none;
}

.countryDropdown input,
.countryDropdown input:hover,
.countryDropdown input:active,
.countryDropdown input:focus,
.countryDropdown input:disabled {
    color: white !important;
    display: flex;
    max-width: 29.5rem;
    height: 3.25rem !important;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 1.875rem !important;
    border: 1px solid rgba(239, 239, 239, 0.2) !important;
    border-image: initial;
    margin-top: 1rem;
    background: none;
    justify-content: space-between;
    align-items: center;
    box-shadow: none !important;
}

.roundbutton,
.roundbutton:hover,
.roundbutton:active,
.roundbutton:focus,
.roundbutton:disabled {
    display: flex;
    width: 7.5rem;
    height: 2.75rem;
    padding: 0.8125rem 2.5625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    border-radius: 1.875rem;
    background: rgb(255, 165, 0);
    border: none;
    margin-top: 1rem;
}

.countryBox {
    max-width: 45rem;
    height: 8.25rem;
    flex-shrink: 0;
    border-radius: 0.75rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: rgba(36, 36, 36, 0.08) 0px 4px 12px 0px;
    backdrop-filter: blur(4px);
    color: rgb(255, 255, 255);
    padding: 1.5rem;

}

.backgroundContainer {
    min-height: 40rem;
}


.navbuttons,
.navbuttons:hover,
.navbuttons:active,
.navbuttons:focus,
.navbuttons:disabled {
    display: flex;
    width: 7.5rem;
    height: 2.75rem;
    padding: 0.8125rem 2.5625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    border-radius: 1.875rem;
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(2px);
    border: none;
    box-shadow: none !important;
}

.navbuttons:hover {
    background-color: var(--primary);
}

.signupButton,
.signupButton,
.signupButton:hover,
.signupButton:active,
.signupButton:focus,
.signupButton:disabled {
    width: 5.5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

}

.languageButton,
.languageButton:hover,
.languageButton:active,
.languageButton:focus,
.languageButton:disabled {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0;
    width: 2rem;
}

.nav-linkActive {
    color: #FFF;
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 850;
    line-height: normal;
    padding-bottom: 0;
}


.dot {
    width: 0.375rem;
    height: 0.375rem;
    background-color: orange;
    border-radius: 50%;
    flex-shrink: 0;
    margin-top: -20px;
}


.more {
    color: #FFA500;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

.moreContainer {
    padding-top: 4.25rem;
}

.moreDescription {
    color: #6F7787;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.bigFatImage {
    width: 28.75rem;
    height: 27.25rem;
    flex-shrink: 0;
    border-radius: 0.75rem;
    background: rgba(36, 36, 36, 0.20);
}

.moreBulletpointHeader {
    color: #242424;
    font-size: 1rem;
    font-style: normal;
    font-weight: 850;
    line-height: normal;
}

.moreBulletpointText {
    color: #6F7787;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1875rem;
    /* 158.333% */
}

.poserNumber {
    color: #FFF;
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.poserText {
    color: #C2C4C7;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1875rem;
    /* 135.714% */
    text-transform: uppercase;
}

.countryListTitle {
    color: #242424;
    font-size: 1rem;
    font-style: normal;
    font-weight: 850;
    line-height: normal;
}

.moreTitle {
    color: #242424;
    text-align: center;
    font-family: "Times New Roman";
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.list-group-item {
    padding: .75rem 0rem !important;
    border: 0;
}

input[type=password],
input[type=text] {
    max-width: 38.9375rem;
    height: 3.5rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid #E2E4E7;
}

.packinglist input[type=text] {
    border-radius: 0.5rem;
    border: 1px solid #E2E4E7;
    padding: 0.69rem;
}

.textform {
    width: 15.1875rem;
    flex-shrink: 0;
    margin-right: 0.5rem;
}

.listAddButton,
.listAddButton:focus,
.listAddButton:active,
.listAddButton:hover {
    border-radius: 0.5rem !important;
    background: #FFA500;
    padding: 0.69rem;
    font-size: 0.75rem;
    width: 4.0625rem;
    flex-shrink: 0;
    color: white;
    box-shadow: none;
}


.countryDescription {
    color: #FFA500;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.countryHeaderInfo {
    color: #242424;
    text-align: center;
    font-family: "Times New Roman";
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
}

.breadcrumb-item,
.breadcrumb-item a,
.breadcrumb-item.active {
    color: #242424;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-decoration: none;
    /* 142.857% */
}

h3.pageTitle {
    color: #242424;
    font-size: 1.25rem !important;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}


.countryInfo {
    color: #242424;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 850;
    line-height: normal;
}

.countryInfo p,
.countryInfo li {
    color: #6F7787;
    font-weight: 400;
    line-height: normal;
}

.footerMainText {
    color: #E2E4E7;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
}

.footerTitle {
    color: #FFF;
    font-size: 1rem;
    font-weight: 850;
    line-height: 1.5rem;
}

.footerContactData {
    color: #6F7787;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}


input[type=checkbox]:checked {
    width: 1.125rem;
    height: 1.125rem;
    flex-shrink: 0;
    fill: #FFA500;
    background-color: #FFA500;
    border: none;
    box-shadow: none;
}

.footerBar {
    color: #FFF;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
}

.languageDropDown {
    margin-left: -8rem;
    flex-shrink: 0;
    border-radius: 0.75rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: rgba(36, 36, 36, 0.08) 0px 4px 12px 0px;
    backdrop-filter: blur(4px);
    color: rgb(255, 255, 255);
}

.authHeader {
    color: #242424;
    font-family: Avenir;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.authLabel {
    color: #242424;
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.44rem;
}

.authButtons,
.authButtons:hover,
.authButtons:active,
.authButtons:focus,
.authButtons:disabled {
    border-radius: 0.5rem;
    background: #FFA500;
    color: white;
    box-shadow: 0px 4px 20px 0px rgba(16, 25, 52, 0.12);
    line-height: normal;
    height: 3.5rem;
}

.authButtons:active {
    border-radius: 0.5rem;
    border: 1.5px solid #FFA500;
}

.authDescription {
    color: #6F7787;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    /* 137.5% */
}

.postIndexLink, .postIndexLink:hover, .postIndexLink:active {
    text-decoration: none;
    color: #242424;
}


.blogpost h3,
.blogpost h2 {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 850;
    line-height: normal;
}


.countryLink,
.countryLinkSelected {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: none;
    background-color: #fff;
    height: 7.75rem;
}

.countryLinkSelected {
    background-color: #000;
    color: #fff;
}

.cardLinkStyle {
    color: black;
    text-decoration: none;
    padding: 0;
    margin: 0;
    width: 11.4375rem;
    height: 7.75rem;
    display: block;
}

@media screen and (max-width: 640px) {
    .navbarBackground {
        padding: 1rem;
        border-radius: 0.75rem;
        background: rgba(255, 255, 255, 0.60);
        box-shadow: 0px 4px 12px 0px rgba(36, 36, 36, 0.08);
        backdrop-filter: blur(4px);
    }

    .nav-link,
    .languageDropDown {
        color: black !important;
    }

    .navbuttons,
    .navbuttons:hover,
    .navbuttons:active,
    .navbuttons:focus,
    .navbuttons:disabled {
        background: var(--primary);
    }
}

@media screen and (min-width: 640px) {
    .countryChooseBox {
        width: 45rem;
    }

    .container-fluid,
    .footerContainer,
    .backgroundContainer {
        padding-left: 7.5rem !important;
        padding-right: 7.5rem;
    }

    .countryDropdown {
        width: 29.5rem;
    }
}